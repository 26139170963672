import { NETWORK_NAME_MAP, Network } from 'paraspace-configs-v2';

import { SupportedNetworks } from '../typings';

export type NetworkConfig = {
  name: string;
  publicJsonRPCUrl: string[]; // public rpc used if not private found, and used to add specific network to wallets if user don't have them. Normally with slow rates
  /**
   * When this is set withdrawals will automatically be unwrapped
   */
  // function returning a link to etherscan
  explorerLink: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
};

export const networkConfigs: { [key in SupportedNetworks]: NetworkConfig } = {
  [Network.SEPOLIA]: {
    name: 'Sepolia',
    publicJsonRPCUrl: [
      'https://1rpc.io/sepolia',
      'wss://ethereum-sepolia-rpc.publicnode.com',
      'https://eth-sepolia.public.blastapi.io',
      'https://sepolia.drpc.org'
    ],
    explorerLink: ['https://sepolia.etherscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.MAINNET]: {
    name: 'Ethereum',
    publicJsonRPCUrl: [
      'https://ethereum-rpc.publicnode.com',
      'https://rpc.ankr.com/eth',
      'https://ethereum.blockpi.network/v1/rpc/public',
      'https://mainnet.gateway.tenderly.co',
      'https://eth.meowrpc.com',
      'https://eth-mainnet.g.alchemy.com/v2/060ueYxTYmJ_Q7ySXooqx8DE9DhYbmH2',
      'https://eth-mainnet.g.alchemy.com/v2/ei6GbPl8gQRMkTQO34pTOMnYQ5ybDE76',
      'https://eth-mainnet.g.alchemy.com/v2/WGWBEXnIJ-dOVnw3JmbpA0PpD5bRmhQp',
      'https://eth-mainnet.g.alchemy.com/v2/PgTeBwRZO6xCDmGk0Y00P1RNx3lu9t0-',
      'https://eth-mainnet.g.alchemy.com/v2/FoSQrujYByoseVlPhB5kM6n2fMaI-WHi',
      'https://eth-mainnet.g.alchemy.com/v2/Xr6mGEL_uTVvnhBDa4Uim3EyrjIzlwxQ',
      'https://eth-mainnet.g.alchemy.com/v2/UBf5NK1UBVPPPmWA-PZT40CrxyOJN_qT',
      'https://eth-mainnet.g.alchemy.com/v2/hLygy78GRbgHhpU6t_qDrgQBzbDtN2E-',
      'https://eth-mainnet.g.alchemy.com/v2/aM4d5NGqWoBPkiPrx7hAgQsq_cId-eJe',
      'https://eth-mainnet.g.alchemy.com/v2/UXiYG_oRap2FACAkkhTjygaDltEBit72',
      'https://eth-mainnet.g.alchemy.com/v2/22ejYRV681Cx_6gPkQz469zfqwCpVncC',
      'https://eth-mainnet.g.alchemy.com/v2/YDpcIz4S50noDfahkKa1v_K78cnBaSa5',
      'https://eth-mainnet.g.alchemy.com/v2/KoSU3zWAWcXKhfELkjvP_rf65wA8y66g',
      'https://eth-mainnet.g.alchemy.com/v2/mWlk5hOsnShMrmym0T65EeV5F1jXjgB_',
      'https://eth-mainnet.g.alchemy.com/v2/93tnHfjID3vDt1hVfPcgQsK544UHZw4F',
      'https://eth-mainnet.g.alchemy.com/v2/JKvODe4QZdfGrA4hpyYDGdqeaG4DzV7T',
      'https://eth-mainnet.g.alchemy.com/v2/Ix8D-EzXnMiqLiCikAKlnPgKX_aehodY',
      'https://eth-mainnet.g.alchemy.com/v2/Avr6P0ldhs7RT4BpIKrk_3vf6iUQnar6',
      'https://eth-mainnet.g.alchemy.com/v2/c_B2dI53xlnlgtxwlE0V_6PqoLw3Hd9p',
      'https://eth-mainnet.g.alchemy.com/v2/xssUkde3a7p7jrb7EN5WDpbJ5D4PhAZj',
      'https://eth-mainnet.g.alchemy.com/v2/jnKiSVeJ4Rq8taP-BdGUwsdyRor2dUQj',
      'https://eth-mainnet.g.alchemy.com/v2/fsAQWnkhTKqKDnSOh4quOWT4VFhOu-no',
      'https://eth-mainnet.g.alchemy.com/v2/r_1NS_HiN0RzJYtDV6xiI-4LWXQGJ28G',
      'https://eth-mainnet.g.alchemy.com/v2/DJJox8vRKsb_8WDtQY2p69qS3xJ3A6Gt'
    ],
    explorerLink: ['https://etherscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ZKSYNC_GOERLI]: {
    name: NETWORK_NAME_MAP[Network.ZKSYNC_GOERLI],
    publicJsonRPCUrl: [
      'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
      'https://testnet.era.zksync.dev'
    ],
    explorerLink: ['https://goerli.explorer.zksync.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ZKSYNC_ERA]: {
    name: NETWORK_NAME_MAP[Network.ZKSYNC_ERA],
    publicJsonRPCUrl: ['https://mainnet.era.zksync.io'],
    explorerLink: ['https://explorer.zksync.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ARBITRUM_ONE]: {
    name: NETWORK_NAME_MAP[Network.ARBITRUM_ONE],
    publicJsonRPCUrl: ['https://arb1.arbitrum.io/rpc'],
    explorerLink: ['https://explorer.arbitrum.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.ARBITRUM_GOERLI]: {
    name: NETWORK_NAME_MAP[Network.ARBITRUM_GOERLI],
    publicJsonRPCUrl: ['https://goerli-rollup.arbitrum.io/rpc'],
    explorerLink: ['https://goerli.arbiscan.io'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [Network.PARALLEL_L3_TESTNET]: {
    name: NETWORK_NAME_MAP[Network.PARALLEL_L3_TESTNET],
    publicJsonRPCUrl: ['https://rpc-accused-coffee-koala-b9fn1dik76.t.conduit.xyz'],
    explorerLink: ['https://explorerl2new-accused-coffee-koala-b9fn1dik76.t.conduit.xyz'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  }
};

import { Network, NETWORK_NAME_MAP } from 'paraspace-configs-v2';
import { find } from 'lodash';

import { Maybe } from '../typings/basic';

import { contractsConfig } from './contractsConfig';

export const SUPPORTED_NETWORKS = (contractsConfig.network.supportedNetworks as Network[]).filter(
  it => it !== Network.FORK
);

export enum Feature {
  UserPoint = 'UserPoint'
}

type NetworkConfig = {
  key: string;
  chainId: Network;
  name: string;
  publicJsonRPCUrl: string[]; // public rpc used if not private found, and used to add specific network to wallets if user don't have them. Normally with slow rates
  /**
   * When this is set withdrawals will automatically be unwrapped
   */
  // function returning a link to etherscan
  explorerLink: string[] | null;
  // deprecated, using Feature instead
  enableNFT: boolean;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  features: Array<Feature>;
};

export const networkConfigs: Partial<{ [key in Network]: NetworkConfig }> = {
  [Network.SEPOLIA]: {
    chainId: Network.SEPOLIA,
    key: 'sepolia',
    name: 'Sepolia',
    publicJsonRPCUrl: [
      'https://1rpc.io/sepolia',
      'wss://ethereum-sepolia-rpc.publicnode.com',
      'https://eth-sepolia.public.blastapi.io',
      'https://sepolia.drpc.org'
    ],
    explorerLink: ['https://sepolia.etherscan.io/'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: [Feature.UserPoint]
  },
  [Network.FORK]: {
    chainId: Network.FORK,
    key: 'fork',
    name: 'Forked Ethereum',
    publicJsonRPCUrl: [''],
    explorerLink: ['https://etherscan.io'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  },
  [Network.MAINNET]: {
    chainId: Network.MAINNET,
    key: 'ethereum',
    name: 'Ethereum',
    publicJsonRPCUrl: [
      'https://ethereum-rpc.publicnode.com',
      'https://rpc.ankr.com/eth',
      'https://ethereum.blockpi.network/v1/rpc/public',
      'https://mainnet.gateway.tenderly.co',
      'https://eth.meowrpc.com',
      'https://eth-mainnet.g.alchemy.com/v2/060ueYxTYmJ_Q7ySXooqx8DE9DhYbmH2',
      'https://eth-mainnet.g.alchemy.com/v2/ei6GbPl8gQRMkTQO34pTOMnYQ5ybDE76',
      'https://eth-mainnet.g.alchemy.com/v2/WGWBEXnIJ-dOVnw3JmbpA0PpD5bRmhQp',
      'https://eth-mainnet.g.alchemy.com/v2/PgTeBwRZO6xCDmGk0Y00P1RNx3lu9t0-',
      'https://eth-mainnet.g.alchemy.com/v2/FoSQrujYByoseVlPhB5kM6n2fMaI-WHi',
      'https://eth-mainnet.g.alchemy.com/v2/Xr6mGEL_uTVvnhBDa4Uim3EyrjIzlwxQ',
      'https://eth-mainnet.g.alchemy.com/v2/UBf5NK1UBVPPPmWA-PZT40CrxyOJN_qT',
      'https://eth-mainnet.g.alchemy.com/v2/hLygy78GRbgHhpU6t_qDrgQBzbDtN2E-',
      'https://eth-mainnet.g.alchemy.com/v2/aM4d5NGqWoBPkiPrx7hAgQsq_cId-eJe',
      'https://eth-mainnet.g.alchemy.com/v2/UXiYG_oRap2FACAkkhTjygaDltEBit72',
      'https://eth-mainnet.g.alchemy.com/v2/22ejYRV681Cx_6gPkQz469zfqwCpVncC',
      'https://eth-mainnet.g.alchemy.com/v2/YDpcIz4S50noDfahkKa1v_K78cnBaSa5',
      'https://eth-mainnet.g.alchemy.com/v2/KoSU3zWAWcXKhfELkjvP_rf65wA8y66g',
      'https://eth-mainnet.g.alchemy.com/v2/mWlk5hOsnShMrmym0T65EeV5F1jXjgB_',
      'https://eth-mainnet.g.alchemy.com/v2/93tnHfjID3vDt1hVfPcgQsK544UHZw4F',
      'https://eth-mainnet.g.alchemy.com/v2/JKvODe4QZdfGrA4hpyYDGdqeaG4DzV7T',
      'https://eth-mainnet.g.alchemy.com/v2/Ix8D-EzXnMiqLiCikAKlnPgKX_aehodY',
      'https://eth-mainnet.g.alchemy.com/v2/Avr6P0ldhs7RT4BpIKrk_3vf6iUQnar6',
      'https://eth-mainnet.g.alchemy.com/v2/c_B2dI53xlnlgtxwlE0V_6PqoLw3Hd9p',
      'https://eth-mainnet.g.alchemy.com/v2/xssUkde3a7p7jrb7EN5WDpbJ5D4PhAZj',
      'https://eth-mainnet.g.alchemy.com/v2/jnKiSVeJ4Rq8taP-BdGUwsdyRor2dUQj',
      'https://eth-mainnet.g.alchemy.com/v2/fsAQWnkhTKqKDnSOh4quOWT4VFhOu-no',
      'https://eth-mainnet.g.alchemy.com/v2/r_1NS_HiN0RzJYtDV6xiI-4LWXQGJ28G',
      'https://eth-mainnet.g.alchemy.com/v2/DJJox8vRKsb_8WDtQY2p69qS3xJ3A6Gt'
    ],
    explorerLink: ['https://etherscan.io'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: [Feature.UserPoint]
  },
  [Network.ARBITRUM_GOERLI]: {
    chainId: Network.ARBITRUM_GOERLI,
    key: 'arbitrum_goerli',
    name: NETWORK_NAME_MAP[Network.ARBITRUM_GOERLI],
    publicJsonRPCUrl: ['https://goerli-rollup.arbitrum.io/rpc'],
    explorerLink: ['https://goerli.arbiscan.io'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  },
  [Network.ARBITRUM_ONE]: {
    chainId: Network.ARBITRUM_ONE,
    key: 'arbitrum_one',
    name: NETWORK_NAME_MAP[Network.ARBITRUM_ONE],
    publicJsonRPCUrl: ['https://arb1.arbitrum.io/rpc'],
    explorerLink: ['https://arbiscan.io'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  },
  [Network.ZKSYNC_ERA]: {
    chainId: Network.ZKSYNC_ERA,
    key: 'zksync_era',
    name: NETWORK_NAME_MAP[Network.ZKSYNC_ERA],
    publicJsonRPCUrl: ['https://mainnet.era.zksync.io'],
    explorerLink: ['https://explorer.zksync.io'],
    enableNFT: false,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  },
  [Network.PARALLEL_TESTNET]: {
    chainId: Network.PARALLEL_TESTNET,
    key: 'parallel_polkadot_testnet',
    name: NETWORK_NAME_MAP[Network.PARALLEL_TESTNET],
    publicJsonRPCUrl: ['https://regnet2-rpc.parallel.fi/v0'],
    explorerLink: ['https://regnet2-rpc.parallel.fi/v0'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  },
  [Network.PARALLEL_L3_TESTNET]: {
    chainId: Network.PARALLEL_L3_TESTNET,
    key: 'parallel_testnet',
    name: NETWORK_NAME_MAP[Network.PARALLEL_L3_TESTNET],
    publicJsonRPCUrl: ['https://rpc-accused-coffee-koala-b9fn1dik76.t.conduit.xyz'],
    explorerLink: ['https://explorerl2new-accused-coffee-koala-b9fn1dik76.t.conduit.xyz'],
    enableNFT: true,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  },
  [Network.PARALLEL]: {
    chainId: Network.PARALLEL,
    key: 'parallel',
    name: NETWORK_NAME_MAP[Network.PARALLEL],
    publicJsonRPCUrl: ['https://rpc.parallel.fi '],
    explorerLink: null,
    enableNFT: false,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    features: []
  }
};

export const getChainKey = (network: Network) => {
  return networkConfigs[network]?.key;
};

export const getNetworkByChainPath = (chainKey: string): Maybe<Network> => {
  return find(networkConfigs, { key: chainKey })?.chainId ?? null;
};

import { useCallback, useMemo } from 'react';
import { ApeStakingService } from 'paraspace-utilities-contract-helpers';
import BigNumberJs from 'bignumber.js';
import { keyBy, mapValues } from 'lodash';

import { useWeb3Context } from '@/apps/paraspace/contexts/Web3Context';
import {
  ApeStakingMainTokenSymbol,
  ApeStakingTokenSymbol,
  ERC20Symbol,
  ERC721Symbol
} from '@/apps/paraspace/typings';
import { useMMProvider } from '@/apps/paraspace/pages/contexts/MMProvider';
import { useAppConfig, useContractsMap } from '@/apps/paraspace/hooks';
import { Feature } from '@/apps/paraspace/config';
import { zero } from '@/apps/paraspace/consts/values';

export type StakingInfoMap = Record<
  string,
  { stakedAmount: BigNumberJs; pendingRewards: BigNumberJs }
>;

type ApeStakingInfo = {
  stakedAmount: BigNumberJs;
  pendingRewards: BigNumberJs;
  stakeLimit: BigNumberJs;
  apy: BigNumberJs;
};

export const SYMBOL_TO_STAKING_INFO_MAP_KEY = {
  [ERC721Symbol.BAYC]: 'baycStakes',
  [ERC721Symbol.MAYC]: 'maycStakes',
  [ERC721Symbol.BAKC]: 'bakcStakes'
};

export const useApeStaking = () => {
  const { provider, account } = useWeb3Context();
  const contracts = useContractsMap();
  const cApeTokenAddr = contracts.cAPE;
  const p2pContractAddr = contracts.P2PPairStaking;
  const { nftInfoMap } = useMMProvider();
  const { features } = useAppConfig();
  const {
    [ERC721Symbol.BAYC]: { xTokenAddress: BAYCxTokenAddr = '' } = {},
    [ERC721Symbol.MAYC]: { xTokenAddress: MAYCxTokenAddr = '' } = {},
    [ERC721Symbol.BAKC]: { xTokenAddress: BAKCxTokenAddr = '' } = {}
  } = nftInfoMap;

  const apeCoinStakingContract = useMemo(
    () => (features.includes(Feature.ApeStaking) ? contracts.ApeCoinStaking : ''),
    [contracts.ApeCoinStaking, features]
  );

  const service = useMemo(() => {
    const apeStakingService = new ApeStakingService(provider, apeCoinStakingContract);
    return apeStakingService;
  }, [apeCoinStakingContract, provider]);

  // currently we only need user's staking amount for single collection
  // hence we return an id-info map here
  const getStakingInfoByUserAddress = useCallback(
    async (user: string = account) => {
      try {
        const stakeInfoMap = await service.getUserApeStakingInfo(user);

        return mapValues(stakeInfoMap, stakeInfo => keyBy(stakeInfo, 'tokenId'));
      } catch (e) {
        return null;
      }
    },
    [account, service]
  );

  const getStakingInfoByTokenInfo = useCallback(
    async (symbol: ApeStakingTokenSymbol, tokenId: string, ownerAddr: string = account) => {
      try {
        const result = await service.getTokensApeStakingInfo([
          { type: symbol, id: tokenId, userAddr: ownerAddr }
        ]);
        return result[0] as ApeStakingInfo;
      } catch (e) {
        console.error('getStakingInfoByTokenInfo failed, error: ', e);
        return null;
      }
    },
    [service, account]
  );

  const getPoolApy = useCallback(
    async (type: ApeStakingTokenSymbol | ERC20Symbol.APE) => {
      try {
        const result = await service.getPoolApy(type);
        return result;
      } catch (e) {
        console.error('getPoolApy failed, error: ', e);
        return zero;
      }
    },
    [service]
  );

  const getStakingInfosByTokenInfos = useCallback(
    async (
      tokens: {
        type: ApeStakingTokenSymbol;
        id: string | number;
        userAddr: string;
      }[]
    ) => {
      try {
        console.log('XX tokens: ', tokens);
        const result = (await service.getTokensApeStakingInfo(
          tokens.map(it => ({ ...it, id: String(it.id) }))
        )) as unknown as Promise<ApeStakingInfo[]>;
        return result;
      } catch (e) {
        console.error('getStakingInfosByTokenInfos failed, error: ', e);
        return [];
      }
    },
    [service]
  );

  const mainToBakcs = useCallback(
    (mainTokens: { symbol: ApeStakingMainTokenSymbol; tokenId: number }[]) => {
      try {
        const result = service.mainToBakc(
          mainTokens.map(main => ({
            type: main.symbol,
            tokenId: main.tokenId
          }))
        );
        return result;
      } catch (e) {
        console.error('get mainToBakcs failed, error: ', e);
        return null;
      }
    },
    [service]
  );

  const getPlatformStakingOverview = useCallback(async () => {
    if (
      !service ||
      !provider ||
      !BAYCxTokenAddr ||
      !MAYCxTokenAddr ||
      !BAKCxTokenAddr ||
      !p2pContractAddr
    ) {
      return null;
    }
    try {
      // ape staking + p2p pool
      const result = await service.getPlatformStakingOverview([
        BAYCxTokenAddr,
        MAYCxTokenAddr,
        BAKCxTokenAddr,
        cApeTokenAddr,
        p2pContractAddr
      ]);
      return result;
    } catch (e) {
      console.error('getPlatformStakingOverview failed, error: ', e);
      return null;
    }
  }, [
    service,
    provider,
    BAYCxTokenAddr,
    MAYCxTokenAddr,
    BAKCxTokenAddr,
    cApeTokenAddr,
    p2pContractAddr
  ]);

  const getSuppliedBakcStakingInfo = useCallback(async () => {
    if (!service || !provider || !BAKCxTokenAddr) {
      return null;
    }
    try {
      const result = await service.getSuppliedBakcStakingInfo(BAKCxTokenAddr);
      return result;
    } catch (e) {
      console.error('getSuppliedBakcStakingInfo falied, error: ', e);
      return null;
    }
  }, [service, provider, BAKCxTokenAddr]);

  return {
    getStakingInfoByUserAddress,
    getStakingInfoByTokenInfo,
    getStakingInfosByTokenInfos,
    getPoolApy,
    mainToBakcs,
    getPlatformStakingOverview,
    getSuppliedBakcStakingInfo
  };
};
